<!-- 注册页面 -->
<template>
  <div class="register-box">
    <van-nav-bar
      title="忘记手机密码"
      left-text=""
      class="black_bg background"
      left-arrow
      style="padding-top: 10px"
      @click-left="onClickLeft"
    />
    <div class="form">
      <div class="international_code">
        <van-field
          readonly
          clickable
          is-link
          class="black_bg background"
          name="picker"
          :value="value"
          placeholder="点击选择国家"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
      </div>
      <div class="phone">
        <span class="phone_number color white_color"
          >+ {{ internationalCode }}</span
        >

        <van-field
          v-model="tel"
          class="black_bg background"
          type="tel"
          placeholder="请输入手机号码"
        />
      </div>
      <div class="verify-code">
        <van-field
          v-model="code"
          class="black_bg background"
          placeholder="请输入验证码"
        />
        <img :src="CodeImg" @click="getGraphicalCode" alt="" />
        <div class="verify-code_right none1" v-if="CodeImg == ''">
          <span
            class="white_color"
            style="font-size: 15px"
            @click="getGraphicalCode"
            >获取图形验证码</span
          >
        </div>
      </div>
      <div class="verify-code">
        <van-field
          v-model="verifyCode"
          class="black_bg background"
          placeholder="请输入验证码"
        />
        <div class="verify-code_right">
          <span
            class="white_color"
            style="font-size: 15px"
            @click="codetxt1 == '获取验证码' ? getVerification() : ''"
            >{{ codetxt1 }}</span
          >
          <!-- <span class="white_color">获取验证码</span> -->
        </div>
      </div>
      <div class="password">
        <van-field
          v-model="password"
          class="black_bg background"
          :type="passwordType"
          placeholder="设置登录密码"
        />
        <div class="eye">
          <img
            :src="passwordType === 'password' ? closeEye : openEye"
            @click="showPwd"
          />
        </div>
      </div>
      <div class="notepass">
        <van-icon name="warning" color="#f07060" />
        <span style="color: #ee715f; font-size: 12px; line-height: 63px"
          >6-20位（支持数字、字母、或特殊符号）</span
        >
      </div>
      <div class="button">
        <van-button @click="affirm" :type="buttonType" size="large"
          >确认</van-button
        >
      </div>
    </div>
    <!-- <div class="loginoregis">
      <router-link :to="{ path: '/login' }"><span>密码登录</span></router-link>
      <router-link :to="{ path: '/register' }"><span>立即注册</span></router-link>
    </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant';
import closeEye from '@/assets/images/close_eye.png'; //闭眼
import openEye from '@/assets/images/open_eye.png'; //睁眼
export default {
  data() {
    return {
      active: 0,
      value: '中国 (大陆)',
      columns: ['中国 (大陆)'],
      showPicker: false,
      internationalCode: '86',
      verifyCode: '',
      tuiJianMa: '',
      tuiJianMayou: '',
      closeEye: closeEye,
      openEye: openEye,
      passwordType: '',
      buttonType: 'primary',
      tel: '',
      email: '',
      password: '',
      telyou: '',
      timer1: '',
      codetxt1: '获取验证码',
      CodeImg: '',
      code: '',
    };
  },

  components: {},

  computed: {},

  methods: {
    // 忘记密码
    affirm() {
      // alert(this.password);
      var reg = /^(?=.*\d)(?=.*[A-z])[\da-zA-Z]{6,20}$/;
      if (reg.test(this.password) == true) {
        let params = {
          name: this.tel,
          checkCode: this.verifyCode,
          password: this.password,
        };
        this.$api.affirm(params).then((res) => {
          if (res.code == 0) {
            Toast.success({
              message: '修改成功',
              forbidClick: true,
            });
            this.$router.push('/login');
          } else if (res.code == 1) {
            this.$toast.fail(res.msg);
          }
        });
      } else {
        console.log('错误');
      }
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    // 获取图片验证码
    getGraphicalCode() {
      if (!this.tel) {
        this.$toast('请输入手机号');
        return;
      }
      let params = {
        tel: this.tel,
      };

      this.$toast.success('获取成功');
      this.$api.getGraphicalCode(params).then((res) => {
        // eslint-disable-next-line no-undef
        this.CodeImg = (URL || webkitURL).createObjectURL(
          new Blob([res], { type: 'img/jpeg' }),
        );
      });
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
    },
    onClickLeft() {
      this.$router.back();
    },

    // 获取验证码
    getVerification() {
      // 加载中,遮罩层
      let toast = Toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      if (!this.tel) {
        this.$toast('请输入手机号');
        return;
      }
      if (!this.code) {
        this.$toast('请输入图形验证码');
        return;
      }
      let params = {
        tel: this.tel,
        code: this.code,
      };
      // 获取图片验证码
      this.$api.anyCheckcodeforget(params).then((res) => {
        if (res.code == 0) {
          // 调用记载中提示
          toast.clear();
          this.$toast.success('验证码已发送');
          let n = 60;
          this.timer1 = setInterval(() => {
            this.codetxt1 = `${n--}s`;
            if (n == 0) {
              this.codetxt1 = '获取验证码';
              clearInterval(this.timer1);
            }
          }, 1000);
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        } else {
          this.$toast.fail('获取失败');
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.register-box {
  display: flex;
  flex-direction: column;
  background: #000;
  width: 100%;
  height: 100vh;

  /deep/.van-nav-bar__arrow {
    color: #fff;
    font-size: 24px;
  }

  /deep/.van-dropdown-menu__title {
    color: #fff;
  }

  /deep/.van-tab {
    color: #d9d9d9;
    font-size: 14px;
    line-height: 26px;
  }

  /deep/.van-tab--active {
    color: #fff;
    font-weight: bold;
  }

  /deep/.van-tabs__line {
    width: 50%;
    border-radius: 0;
    height: 1px;
    background-color: #fff;
  }

  /deep/.van-tabs__nav--line {
    background-color: #000;
  }

  /deep/.van-tabs__wrap {
    margin: 0 22px;
  }

  /deep/.van-tabs--line .van-tabs__wrap {
    border-bottom: 1px solid rgba(37, 37, 37, 1);
  }

  /deep/.van-nav-bar__title {
    color: #fff;
  }

  .van-button--large {
    height: 40px;
  }

  .form {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    font-size: 16px;
    color: #c2c2c2;

    .international_code {
    }

    /deep/ .van-field__control {
      color: #fff !important;
    }

    .van-cell {
      padding: 10px 0;
    }

    .phone {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #252525;
      padding: 5px 0;

      .phone_number {
        display: flex;
        width: 18%;
        padding: 0 10px 0 5px;
      }

      .phone_number::after {
        content: ' ';
        display: flex;
        width: 1px;
        height: 19px;
        margin-left: 10px;
        background: #eeeeee;
        margin-top: 2px;
      }

      .emailar {
        width: 40%;
      }
    }

    .verify-code {
      display: flex;
      border-bottom: 1px solid #252525;
      align-items: center;
      padding: 5px 0;

      .verify-code_right {
        display: flex;
        width: 48%;
        align-items: center;

        span {
          display: flex;
          align-items: center;
        }

        span::before {
          content: ' ';
          display: flex;
          width: 1px;
          height: 19px;
          margin-right: 10px;
          background: #eeeeee;
        }
      }
    }

    .tuijianma {
      display: flex;
      border-bottom: 1px solid #252525;
      align-items: center;
      padding: 5px 0;

      .tuijianma_right {
        display: flex;
        width: 48%;
        align-items: center;
        justify-content: space-between;

        span {
          display: flex;
          align-items: center;
        }

        span::before {
          content: ' ';
          display: flex;
          width: 1px;
          height: 19px;
          margin-right: 10px;
          background: #eeeeee;
        }
      }
    }

    .password {
      display: flex;
      border-bottom: 1px solid #252525;
      align-items: center;
      padding: 5px 0;

      span {
        display: flex;
        width: 38%;
        align-items: center;
      }

      span::before {
        content: ' ';
        display: flex;
        width: 1px;
        height: 19px;
        margin-right: 10px;
        background: #eeeeee;
      }
    }

    .button {
      margin-top: 30px;

      .van-button--primary {
        color: #fff !important;
        background: #0754d3;
        border: 1px solid #0754d3;
      }

      .van-button--default {
        color: #fff !important;
        background: #0754d3;
        border: 1px solid #0754d3;
      }
    }
  }

  .loginoregis {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    text-align: center;
    margin: 5px 20px;

    a {
      color: #181818;
    }
  }

  .eye {
    display: flex;

    img {
      width: 17px;
      height: auto;
      padding: 0 20px;
    }
  }

  .notepass {
    font-size: 12px;
    margin-top: 10px;
    color: #f07060;

    span {
      margin-left: 10px;
    }
  }
}

.none1 {
  span::before {
    content: ' ';
    display: flex;
    width: 0px;
    height: 0px;
    margin-right: 10px;
    background: rgba(218, 218, 218, 1);
  }
}
/deep/.background {
  background-color: black;
}
</style>
